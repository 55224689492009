import React from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import AppBackground from './AppBackground';
import AppSurface from './AppSurface';
import LoadingSection from '../UI/LoadingSection';
import Stack from '@mui/material/Stack';
import { useLayout } from './LayoutContext';
import { DesignType, useDesignTheme } from './DesignThemes';
import { ButtonBase } from '@mui/material';
import HyveLogoCentered from './HyveLogoCentered';
import HeaderLogo from './HeaderLogo';

type PageStyleProps = {
  design?: DesignType;
  theme?: string;
  loading?: boolean;
  notice?: React.ReactNode;
  fullWidth?: boolean;
  children?: React.ReactNode;
  includeHeader?: boolean;
  includeBrandedLogo?: boolean; // only applies to the branded design
  backgroundProps?: Object;
  surfaceProps?: Object;
};

const PageStyle = ({
  design = 'standard',
  loading = false,
  notice = undefined,
  fullWidth = true,
  children,
  includeHeader = false,
  includeBrandedLogo = true,
  backgroundProps,
  surfaceProps,
}: PageStyleProps) => {
  const currentTheme = useTheme();
  const designThemeOptions = useDesignTheme(design);
  const layout = useLayout();

  let pageTheme = currentTheme;
  pageTheme = deepmerge(pageTheme, designThemeOptions);

  if (loading) {
    children = <LoadingSection />;
  }

  let content;
  if (design === 'standard') {
    content = children;
  } else if (design === 'custom') {
    content = children;
  } else if (design === 'branded') {
    fullWidth = true;
    content = (
      <AppSurface {...surfaceProps}>
        {includeBrandedLogo && <HyveLogoCentered marginTop='40px' marginBottom='16px' />}
        {children}
      </AppSurface>
    );
  } else {
    content = <AppSurface {...surfaceProps}>{children}</AppSurface>;
  }

  let maxWidth = 1080;
  if (layout === 'desktop') {
    maxWidth = 1600;
  }

  const sx = {
    ...(fullWidth && { width: '100%', alignItems: 'stretch' }),
    maxWidth: `${maxWidth}px`,
    ...(design === 'branded' && { alignItems: 'center', justifyContent: 'center' }),
  };

  return (
    <ThemeProvider theme={pageTheme}>
      {includeHeader && <Header />}
      <AppBackground {...backgroundProps}>
        {notice}
        <Stack flex={1} spacing={4} className='app-page' alignItems='center' sx={sx}>
          {content}
        </Stack>
      </AppBackground>
    </ThemeProvider>
  );
};

const Header = () => (
  <Stack data-component='Header' direction='row' height='64px' sx={{ backgroundColor: '#000' }} alignItems='center'>
    <ButtonBase href='/'>
      <HeaderLogo />
    </ButtonBase>
  </Stack>
);

export default PageStyle;
